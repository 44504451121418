import log from '@/calendesk/prototypes/log'
import { getAccessToken } from '@/calendesk/prototypes/token'
import { resetApp } from '@/calendesk/api/ResetApp'
import { httpClient } from '@/calendesk/api/HttpClient'
import { AxiosRequestConfig, Method } from 'axios'
import * as storage from '@/calendesk/tools/storage'
import { trans } from '@/calendesk/prototypes/trans'
import store from '@/store'
import { verifyEmail } from '@/calendesk/api/VerifyEmail'
import { captchaFailedHandle } from '@/calendesk/api/CaptchaFailedHandle'

export async function sendRequest (
  method: Method,
  url: string,
  data: Record<string, any> | null = null,
  sendWithAuth = false,
  headers: Record<string, string | number | boolean> = {},
  otherOptions: Record<string, string | number | boolean> = {},
  emailToVerify: string | null = null): Promise<any> {
  const tenant = store.getters['setup/getTenant'] || storage.local.getItem('tenant')
  const draftUuid = store.getters['setup/getDraftUuid'] || storage.local.getItem('draft_uuid')

  if (!tenant || (sendWithAuth && !getAccessToken())) {
    if (url !== 'v2/tenants/domain') {
      resetApp(trans('force_reload_message'))
      throw new Error('TENANT_UNKNOWN')
    }
  }

  // Handle email verification
  if (emailToVerify) {
    try {
      headers = await verifyEmail(emailToVerify, headers)
    } catch (error) {
      log.info('Error in email verification:', error)
      throw new Error('EXTRA_EMAIL_VERIFICATION_FAILED')
    }
  }

  try {
    const requestConfig: AxiosRequestConfig = {
      method: method,
      url: url,
      params: method === 'GET' ? data : null,
      data: method !== 'GET' ? data : null,
      headers: {
        'X-Tenant': tenant,
        'X-Draft-Uuid': draftUuid,
        ...headers
      },
      ...otherOptions
    }

    const locale = storage.local.getItem('locale')

    if (locale) {
      requestConfig.headers['Accept-Language'] = locale
    }

    if (sendWithAuth) {
      requestConfig.headers.Authorization = `Bearer ${getAccessToken()}`
    }

    log.info('API request: ', requestConfig)

    const response = await httpClient.request(requestConfig)
    log.info('API response: ', [requestConfig, response])
    return response
  } catch (error) {
    const errorData = error && error.response && error.response.data ? JSON.stringify(error.response.data) : error
    log.info('API response catch: ', [url, errorData])

    if (url === 'auth/token/refresh' || url === 'settings/all') {
      resetApp(trans('force_reload_message'), true)
    }

    if (error?.response?.data?.code === 'CAPTCHA_INVALID') {
      return captchaFailedHandle(
        method,
        url,
        data,
        sendWithAuth,
        headers,
        otherOptions
      )
    }

    throw error
  }
}
