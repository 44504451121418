















































































import APIClient from '@/calendesk/api/APIClient'
import CalendeskWarningMessage from '@/components/shared/CalendeskWarningMessage.vue'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { EventBus } from '@/calendesk/plugins/eventBus'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import VerifyCodeRequestData from '@/calendesk/models/DTO/Request/VerifyCodeRequestData'
import VerifyEmailRequestData from '@/calendesk/models/DTO/Request/VerifyEmailRequestData'

export default mixins(DraftElement).extend({
  name: 'EmailExtraVerification',
  components: { CalendeskWarningMessage },
  data () {
    return {
      showDialog: false,
      emailToVerify: null as string | null,
      code: null as string | null,
      resolveCallback: null as Function | null,
      rejectCallback: null as Function | null,
      isSendingCode: false,
      isVerifyingCode: false,
      canNotGenerateCodeError: false
    }
  },
  created () {
    EventBus.$on(
      'EMAIL_EXTRA_VERIFICATION',
      this.handleEmailExtraVerificationEvent
    )
  },
  beforeDestroy () {
    EventBus.$off(
      'EMAIL_EXTRA_VERIFICATION',
      this.handleEmailExtraVerificationEvent
    )
  },
  methods: {
    verifyCode () {
      if (this.code && this.emailToVerify) {
        this.isVerifyingCode = true

        const data = new VerifyCodeRequestData(this.code, this.emailToVerify)

        APIClient
          .verifyCode(data)
          .then(() => {
            if (this.resolveCallback) {
              this.resolveCallback(this.code)
            }
            this.closeDialog()
          })
          .catch((error: any) => {
            errorNotification('verify_code_incorrect_error', error, false)
          })
          .finally(() => {
            this.isVerifyingCode = false
          })
      } else {
        errorNotification('verify_code_title', null, false)
      }
    },
    closeDialog () {
      this.showDialog = false

      if (this.rejectCallback) {
        this.rejectCallback()
      }

      this.clearData()
    },
    handleEmailExtraVerificationEvent (resolve: Function, reject: Function, emailToVerify: string) {
      this.showDialog = true
      this.emailToVerify = emailToVerify
      this.resolveCallback = resolve
      this.rejectCallback = reject
      this.sendVerificationCode()
    },
    sendVerificationCode () {
      this.isSendingCode = true

      if (this.emailToVerify) {
        const data = new VerifyEmailRequestData(this.emailToVerify)

        APIClient
          .verifyEmail(data)
          .then(() => {
            this.canNotGenerateCodeError = false
          })
          .catch(() => {
            this.canNotGenerateCodeError = true
          })
          .finally(() => {
            this.isSendingCode = false
          })
      } else {
        errorNotification('verification_invalid_error_notification', null, false)
      }
    },
    clearData () {
      this.emailToVerify = null
      this.code = null
      this.resolveCallback = null
      this.rejectCallback = null
      this.canNotGenerateCodeError = false
      this.isSendingCode = false
      this.isVerifyingCode = false
    }
  }
})
