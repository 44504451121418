import { Method } from 'axios'
import log from '@/calendesk/prototypes/log'
import { sendRequest } from '@/calendesk/api/SendRequest'

export async function captchaFailedHandle (
  method: Method,
  url: string,
  data: Record<string, any> | null = null,
  sendWithAuth = false,
  headers: Record<string, string | number | boolean> = {},
  otherOptions: Record<string, string | number | boolean> = {}
) {
  // When captcha fails,
  // we can validate user's email and get the stronger captcha code that proofs we deal with a human.
  log.info('Attempting email verification due to CAPTCHA failure.', data)

  let emailToVerify = null

  if (url === 'user/bookings/create/no-auth') {
    emailToVerify = data?.user?.email
  }

  if (emailToVerify) {
    return sendRequest(
      method,
      url,
      data,
      sendWithAuth,
      headers,
      otherOptions,
      emailToVerify
    )
  }

  throw new Error('EXTRA_EMAIL_VERIFICATION_FAILED')
}
